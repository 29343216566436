@import "../variables.module";

.green {
  color: $secondary-stroke-color;

  * {
    color: inherit;
  }
}

.game-side-bar-container {
  width: 340px;
  border-radius: $radius;
  flex-shrink: 0;

  &.mobile-two {
    width: 100%;
  }

  @media (max-width: $breakPointOne) {
    width: 100%;
  }

  .top-field {
    border-radius: $radius;
    padding: $padding;
    padding-top: 20px;
    background-color: $fifthBackground;

    display: flex;
    flex-direction: column;
    margin-bottom: $padding;

    .bottom-background {
      display: flex;
      flex-direction: column;

      @media (max-width: 520px) {
        flex-direction: column-reverse;
        gap: 16px;
      }
    }
  }

  .player-display-field {
    background-color: $secondaryBackgroundColor;
    border-radius: $radius;
    padding-top: $padding;
    width: 100%;
    font-size: $fontSizeFour;

    span {
      font-family: $boldFont;
      color: $secondaryFontColor;
    }

    .heading {
      padding-left: $padding;
    }

    .stats-header {
      padding: $padding;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: $fontSizeFour;
      align-items: center;
      padding-top: 10px;

      .user-count {
        display: flex;
        gap: 5px;

        p {
          color: $secondaryFontColor;
        }
      }

      .player-pot {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  .dice-profit-display {
    display: flex;
    justify-content: space-between;
    font-family: $boldFont;
    padding-top: $padding;
    padding-bottom: 10px;

    span {
      color: $rouletteGreen;
      font-size: 28px;
    }

    .profit {
      h3 {
        color: $fontColorTwo;
        padding-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .value-icon {
          width: 30px;
          display: flex;

          svg {
            width: 100%;
          }
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 175px;
        }
      }
    }

    .icon {
      margin-right: -$padding;
      margin-top: -30px;
      position: relative;

      svg {
        width: 110px;
      }
    }
  }

  .user-display-list {
    padding-left: $padding;
    overflow-y: auto;
    width: calc(100%);
    @include scrollbars(0.5em, $scrollBarColor, rgba(0, 0, 0, 0));
    height: 300px;
    padding-right: 10px;
    overflow-y: auto;

    .player-display-item {
      border-radius: $radius;
      height: 45px;
      margin-bottom: 5px;
      padding-right: 15px;
      background-color: $btnFillTwo;
      align-items: center;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      p {
        font-family: $boldFont;
      }

      &:nth-child(even) {
        @include gradient(40deg, $btnFillTwo, rgba(0, 0, 0, 0));
        background: linear-gradient(to right, rgba(0, 0, 0, 1));
      }

      .color-gradient {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 20%;
        z-index: 0;

        &.r {
          @include gradient(
            to right,
            rgba(217, 43, 80, 0.3),
            rgba(217, 43, 80, 0)
          );
        }

        &.b {
          @include gradient(
            to right,
            rgba(78, 83, 123, 0.3),
            rgba(78, 83, 123, 0)
          );
        }

        &.g {
          @include gradient(
            to right,
            rgba(114, 215, 108, 0.3),
            rgba(114, 215, 108, 0)
          );
        }
      }

      .multiplier {
        margin-left: auto;
        margin-right: $padding;
        line-height: 1em;
      }

      .left-section {
        text-align: center;
        padding-left: 20px;
        font-family: $boldFont;
        font-size: $fontSizeFour;
        display: flex;
        z-index: 2;
        transition: opacity 500ms $easeInOut3;
        justify-content: space-between;

        &.won {
          color: $green;
        }

        .hidden {
          color: #7179a5;
          display: flex;
          gap: 5px;
          align-items: center;
          align-content: center;
        }

        .roulette-indicator {
          margin-right: 10px;
          border-radius: 50%;
          width: 13px;
          height: 13px;
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            border-radius: 50%;
            width: 5px;
            height: 5px;
          }
        }

        &.mult-color-r {
          .roulette-indicator {
            background-color: rgba(217, 43, 80, 0.5);

            div {
              background-color: $rouletteRed;
            }
          }
        }

        &.mult-color-g {
          .roulette-indicator {
            background-color: rgba(114, 215, 108, 0.5);

            div {
              background-color: $rouletteGreen;
            }
          }
        }

        &.mult-color-b {
          .roulette-indicator {
            background-color: rgba(78, 83, 123, 0.5);

            div {
              background-color: $rouletteBlack;
            }
          }
        }

        &.game-over {
          opacity: 0.1;
        }

        &.win {
          opacity: 1;
        }
      }

      .bet-amount {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          padding-top: 2px;
        }

        &.won {
          p {
            color: $green;
          }
        }
      }
    }
  }
}

.game-description {
  width: 100%;
  margin-top: 40px;
  border-radius: 6px;
  padding: 25px;
  background-color: $secondaryBackgroundColor;
  display: flex;
  gap: 30px;

  @media (max-width: 650px) {
    margin-top: 40px;
  }

  @media (max-width: 450px) {
    gap: 20px;
  }

  &.mobile-three {
    flex-direction: column;
    background-color: transparent;
    padding: 0px;

    .game-image-container {
      display: flex;
      gap: 20px;
      flex-basis: 170px;
      max-width: none;

      .game-image {
        max-width: 130px;
      }
      hr {
        display: none;
      }
    }
  }

  &__slot-mobile-display {
    margin-top: 40px;
    flex-direction: column;

    .game-image-container {
      width: 100%;
      gap: 20px;
      display: flex;
      max-width: none !important;
      flex-basis: auto !important;

      .game-image {
        max-width: 162px;
        min-width: 122px;

        @media (max-width: 450px) {
          max-width: 100px !important;
        }
      }
    }

    .meta-container {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;
      hr {
        display: none;
      }

      .mobile-heading {
        font-size: 13px;
        margin-bottom: 20px;
        line-height: 1.4;
      }
    }
  }

  .count {
    min-width: 100px;
    &__type {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 8px;
      height: 20px;

      svg {
        path {
          fill: linear-gradient(180deg, #5cb4ff 0%, #0082f0 100%), #0077db;
        }
      }
    }

    &__title {
      color: white;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 14px;
    }

    &__value {
      span {
        color: #e8e5ff;
        font-weight: 500;
        margin-right: 6px;
      }
      border-radius: 8px;
      border: 1px solid #7c83b125;
      background:
        linear-gradient(
          180deg,
          rgba(124, 131, 177, 0.05) 0%,
          rgba(94, 103, 158, 0.05) 100%
        ),
        rgba(38, 44, 82, 0.3);
      display: flex;
      padding: 10px 15px;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 180px;
      align-self: stretch;
      color: rgba(232, 229, 255, 0.75);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  h1 {
    margin-bottom: 27px;
    color: $primaryFontColor;
    font-size: 14px;
  }

  p {
    font-size: 13px;
    color: $fontColorTwo;
  }

  .game-image-container {
    flex-basis: 180px;
    width: 100%;
    max-width: 180px;
    position: relative;

    img {
      border-radius: $radius;
    }

    @media (max-width: 992px) {
      max-width: 170px;
    }
  }

  .game-description-bottom-container {
    font-size: 13px;
    min-height: 170px;
    width: 100%;
    color: $fontColorTwo;

    @media (min-width: $breakPointOne) {
      width: calc(100% - 210px);
    }

    h1 {
      margin-bottom: 15px;
    }

    &.mobile-version {
      h2 {
        display: none;
      }
    }
  }

  .game-description-bottom-buttons {
    display: flex;
    padding: 5px;
    border-radius: 6px;
    display: inline-flex;
    background-color: $fourthBackground;
    padding: 4px;
    gap: 1px;
    border: 2px solid #282f58;
    margin-bottom: 28px;

    h2 {
      font-size: 20px;
      margin: 0px;
      line-height: 1em;
    }

    h2 {
      font-size: 15px;
      color: #c1c4dc;
      line-height: 1.4em;
      margin: 15px 0px 0px 0px;
    }

    H3 {
      font-size: 14px;
      color: #c1c4dc;
      line-height: 1.4em;
      margin: 15px 0px 0px 0px;
    }

    H4,
    H5,
    H6 {
      font-size: 14px;
      line-height: 1.4em;
      margin: 20px 0px 0px 0px;
    }
  }

  .game-content {
    line-height: 1.5em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
    }
    h1 {
      font-size: 20px;
      margin: 0px;
      line-height: 1em;
      margin: 15px 0px 0px 0px;
    }

    H2 {
      font-size: 15px;
      color: #c1c4dc;
      line-height: 1.4em;
      margin: 15px 0px 0px 0px;
    }

    H3 {
      font-size: 14px;
      color: #c1c4dc;
      line-height: 1.4em;
      margin: 15px 0px 0px 0px;
    }

    H4,
    H5,
    H6 {
      font-size: 14px;
      line-height: 1.4em;
      margin: 15px 0px 0px 0px;
    }

    p {
      font-size: 14px;
      margin: 15px 0 0 0;
    }
    div:has(table) {
      padding: 16px;
      margin-top: 20px;
      border-radius: 8px;
      border: 1px solid #7c83b150;
      background:
        linear-gradient(
          180deg,
          rgba(124, 131, 177, 0.05) 0%,
          rgba(94, 103, 158, 0.05) 100%
        ),
        rgba(38, 44, 82, 0.3);
      table {
        width: 100% !important;
        @media screen and (max-width: $modalBreakPointOne) {
          min-width: 450px;
        }
        tbody {
          tr {
            p {
              margin: 10px 0;
            }
            td {
              padding-left: 4px;
            }
            :first-child {
              p {
                color: #e8e5ff;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
            &:not(:last-child) {
              border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            }
          }
        }
      }
      @media screen and (max-width: $modalBreakPointOne) {
        overflow-x: scroll;
      }
    }
    ol,
    ul {
      list-style-type: decimal;
      li {
        text-align: left;
        margin-left: 1rem;
        font-size: 14px;
        line-height: 1.5em;
        margin-top: 15px;
        color: #b2b1c9;
      }
    }
  }
}

.game-settings-btn {
  bottom: $padding;
  left: $padding;
  display: flex;
  align-items: center;
  align-content: center;
  gap: $padding/2;
  z-index: 10;

  &.crash {
    top: $padding;
    left: $padding;
    right: $padding;
    bottom: auto;
    display: flex;
    justify-content: space-between;
  }

  &.dice {
    bottom: auto;
    left: auto;
    right: $padding;
    top: $padding;
  }

  &.case {
    position: absolute;
    left: auto;
    right: 0;
  }
}

.roulette-btn-container {
  .heading {
    padding-bottom: 5px;
    font-size: $fontSizeFour;
    color: $fontColorTwo;
    font-family: $boldFont;
  }

  .btn-container {
    display: flex;
    gap: 10px;
    transition: opacity 500ms $easeInOut3;

    &.hide {
      opacity: 0.25;
      pointer-events: none;

      .bet-color-select {
        pointer-events: none;
      }
    }

    .bet-color-select {
      background-color: #37ba2f;
      width: 100%;
      position: absolute;
      bottom: 4px;
      top: 0px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: bottom 200ms $easeInOut3;

      &.green {
        @include gradient(45deg, #7fd879, #37ba2f);
      }

      &.red {
        @include gradient(45deg, #e33952, #ac003e);
      }

      &.black {
        @include gradient(45deg, #383f62, #303458);
      }

      .color-name {
        font-family: $mainFont;
        font-size: 10px;
      }

      span {
        font-family: $boldFont;
        font-size: 15px;
        color: #fff;
      }
    }
  }
}

.mobile-bet-btn-container {
  margin-bottom: 10px;
}
